import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { environment } from 'environments/environment';
import { User, UserProfileData } from 'app/auth/models';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators'
import { EncryptionService } from 'app/utils/encryption/encryption.service';

import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { AuthService } from './auth.service';

const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';


@Injectable({ providedIn: 'root' })
export class UserService {
  /**
   *
   * @param {HttpClient} _http
   */
  currntUserDetails: UserProfileData;
  constructor(
    private _http: HttpClient,
    private _encryptionService: EncryptionService,
    private auth: AuthService,
  ) {
    this.setUserData();
  }

  registerSubAdmin(data): Observable<any> {
    let enc = this._encryptionService.encode(JSON.stringify(data));
    let term = 'registerSubAdmin';
    return this._http.post(`${environment.baseApiUrl}${term}`, { enc });

  }

  addManager(data, toupdate = null): Observable<any> {
    // {user_id,pack_one:amount,pack_two:amount,pack_three:amount}
    let enc = this._encryptionService.encode(JSON.stringify(data));
    let term = (toupdate) ? 'updateManager' : 'addManager';
    return this._http.post(`${environment.baseApiUrl}${term}`, { enc })
      .pipe(
        map(data => {
          return this._encryptionService.getDecode(data);
        })
      )
  }

  deleteManager(data): Observable<any> {
    // {user_id}
    let enc = this._encryptionService.encode(JSON.stringify(data));
    return this._http.post(`${environment.baseApiUrl}deleteManager`, { enc })
      .pipe(
        map(data => {
          return this._encryptionService.getDecode(data);
        })
      )
  }

  /**
   * Get all users
   */
  getAll(): Observable<any> {
    return this._http.get<User[]>(`${environment.apiUrl}/getAllUser?searchStr=`)
      .pipe(
        map(data => {
          return this._encryptionService.getDecode(data);
        })
      )
  }

  /**
   * Get user by id
   */
  getById(id: number) {
    return this._http.get<User>(`${environment.apiUrl}/users/${id}`);
  }

  getUserDetails(enc) {
    return this._http.post<any>(`${environment.baseApiUrl}getUserById`, enc);
  }

  updateUserDetails(encData: any) {
    return this._http.post<any>(`${environment.baseApiUrl}updatePersonalInfo`, encData);
  }

  verfifyDocument(encData: any) {
    return this._http.post<any>(`${environment.baseApiUrl}KYCApproved`, encData);
  }

  getWhiteListDetails(){
    return this._http.get<any>(`${environment.baseApiUrl}getWhitlist`);
  }

  updateWhiteList(data:any){
    return this._http.post<any>(`${environment.baseApiUrl}approveWhitlist`, data);
  }

  changePassword(encData: any) {
    return this._http.post<any>(`${environment.baseApiUrl}changePassword`, encData);
  }

  gettwoFactAuthEnable(encData: any) {
    return this._http.put<any>(`${environment.baseApiUrl}twoFactAuthEnable`, encData);
  }

  twoFactAuthVerify(encData: any) {
    // {user_id,srcode} as enc
    return this._http.post<any>(`${environment.baseApiUrl}twoFactAuthVerify`, encData);
  }

  chaneAntiPhisingCode(encData: any) {
    // {user_id,srcode} as enc
    return this._http.post<any>(`${environment.baseApiUrl}chaneAntiPhisingCode`, encData);
  }

  twoFactAuthDisable(encData: any) {
    // {user_id,srcode} as enc
    return this._http.post<any>(`${environment.baseApiUrl}twoFactAuthDisable`, encData);
  }

  sponsorAll(id: any) {
    // {user_id,srcode} as enc
    return this._http.get<any>(`${environment.baseApiUrl}getSponsorList/${id}`);
  }

  getUserStats(data:any){
    return this._http.post<any>(`${environment.baseApiUrl}getUserStat`, data);

  }

  uploadProfilePic(formData): Observable<any> {
    let headers = new HttpHeaders();
    //this is the important step. You need to set content type as null
    headers.append('Content-Type', undefined);
    return this._http.post(`${environment.baseApiUrl}uploadProfilePic/`, formData)

  }

  identification_file(formData): any {
    let headers = new HttpHeaders();
    headers.append('Content-Type', undefined);
    return this._http.post(`${environment.baseApiUrl}identification`, formData)
  }
  getCurrentAntiCode(enc): Observable<any> {
    return this._http.get(`${environment.baseApiUrl}getCurrentAntiCode/${enc}`,)
  }

  changeUserSt(enc): Observable<any> {
    return this._http.get(`${environment.baseApiUrl}activeInactiveUser/${enc}`)
  }

  setUserData(data?: any) {
    if (data) {
      this.currntUserDetails.address = (data.address) ? data.address : this.currntUserDetails.address ? this.currntUserDetails.address : '';
      this.currntUserDetails.full_name = (data.full_name) ? data.full_name : this.currntUserDetails.full_name ? this.currntUserDetails.full_name : '';
      this.currntUserDetails.id_passport = (data.id_passport) ? data.id_passport : this.currntUserDetails.id_passport ? this.currntUserDetails.id_passport : '';
      this.currntUserDetails.is_company = (data.is_company) ? data.is_company : this.currntUserDetails.is_company ? this.currntUserDetails.is_company : 0;
      this.currntUserDetails.company_name = (data.company_name) ? data.company_name : this.currntUserDetails.company_name ? this.currntUserDetails.company_name : '';
      this.currntUserDetails.avoid_retention = (data.avoid_retention) ? data.avoid_retention : this.currntUserDetails.avoid_retention ? this.currntUserDetails.avoid_retention : 0;
      this.currntUserDetails.city = (data.city) ? data.city : this.currntUserDetails.city ? this.currntUserDetails.city : '';
      this.currntUserDetails.company_id = (data.company_id) ? data.company_id : this.currntUserDetails.company_id ? this.currntUserDetails.company_id : '';
      this.currntUserDetails.country = (data.country) ? data.country : this.currntUserDetails.country ? this.currntUserDetails.country : '';
      this.currntUserDetails.date_of_birth = (data.date_of_birth) ? this.fixDateFormat(data.date_of_birth) : this.currntUserDetails.date_of_birth ? this.fixDateFormat(this.currntUserDetails.date_of_birth) : '';
      this.currntUserDetails.lang_pref = (data.lang_pref) ? data.lang_pref : this.currntUserDetails.lang_pref ? this.currntUserDetails.lang_pref : '';
      this.currntUserDetails.phone = (data.phone) ? data.phone : this.currntUserDetails.phone ? this.currntUserDetails.phone : '';
      this.currntUserDetails.postcode = (data.postcode) ? data.postcode : this.currntUserDetails.postcode ? this.currntUserDetails.postcode : '';
      this.currntUserDetails.profile_picture = (data.profile_picture) ? data.profile_picture : this.currntUserDetails.profile_picture ? this.currntUserDetails.profile_picture : '';
    } else {
      this.currntUserDetails = {
        address: '',
        avoid_retention: 0,
        city: '',
        company_id: null,
        company_name: null,
        country: '',
        date_of_birth: '',
        full_name: '',
        id_passport: '',
        is_company: 0,
        lang_pref: '',
        phone: '',
        postcode: '',
        profile_picture: '',
      }
    }
  }

  fixDateFormat(dateString: any) {
    // "1988-08-23T00:00:00.000Z"
    return dateString ? dateString.replace('T', ' ').replace('.000Z', '') : dateString;
  }

  assignPacktoUser(data): Observable<any> {
    let enc = this._encryptionService.encode(JSON.stringify(data));
    return this._http.post(`${environment.baseApiUrl}assignPack`, { enc }).pipe(map(data => {
      return this._encryptionService.getDecode(data);
    }))
  }


  getBtomaticMinAmounts() {
    return this._http.get(`${environment.baseApiUrl}getMinAmounts`)
  }

  updateBtomaticMinAmounts(input_data) {
    let enc = this._encryptionService.encode(JSON.stringify(input_data));
    return this._http.post(`${environment.baseApiUrl}updateMinAmounts`, { enc: enc })
  }


  getUserIdentity(input_data): any {
    return this._http.get(`${environment.baseApiUrl}getUserIdentity/` + input_data)
  }

  approvedIdentification(input_data): any {
    return this._http.post(`${environment.baseApiUrl}approvedIdentification`, input_data)
  }


  updateUnilevel(input_data) {
    return this._http.post(`${environment.baseApiUrl}upsertLevelPercentage`, input_data)
  }
  getfeeDetail() {
    return this._http.get(`${environment.baseApiUrl}getLevelPercentage`)
  }


  // generate xl

  public exportAsExcelFile(json: any[], excelFileName: string): void {

    let newJson = [];
    json.forEach(el => {
      newJson.push({
        FirstName: el.first_name,
        LastName: el.last_name,
        Country: el.country,
        Email: el.email,
        Status: el.active == 1 ? 'Active' : 'In-active',
      })
    })
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(newJson);
    const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
    XLSX.writeFile(workbook, this.toExportFileName(excelFileName));
  }
  toExportFileName(excelFileName: string): string {
    return `${excelFileName}_export_${new Date().getTime()}.xlsx`;
  }



  getNotification(id): any {
    return this._http.get(`${environment.apiUrl}getNotification/${id}`).pipe(map(data => {
      return this.auth.getDecode(data);
    }));

  }

  setNotificationMarked(data): Observable<any> {
    return this._http.post(`${environment.apiUrl}/notificationRead`, data)

  }

  getAllPaymentHistory(){
    return this._http.get(`${environment.baseApiUrl}getAllPaymentHistory`)
  }

  getAllWithdrawals(status: any) {
    return this._http.get(`${environment.baseApiUrl}getAllWithdrawals?status=${status}`);
  }
  approveUserWihdrawalRequest(data:any){
    return this._http.post(`${environment.apiUrl}/approveUserWihdrawalRequest`,data)
  }
  getAllUserStats() {
    return this._http.get(`${environment.baseApiUrl}getAllUserStats`);
  }
}
